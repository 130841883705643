import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  StyledFigure, StyledFigcaption, StyledImage, StyledDescription,
} from './Figure.styled';

const Figure = ({
  image, title, description, smaller, alt, team, ...props
}) => (
  <StyledFigure smaller={smaller} team={team} props={props}>
    {team ? (
      <GatsbyImage
        image={image}
        alt={alt}
      />
    ) : (
      <StyledImage src={image} alt={alt} />
    )}
    {title && <StyledFigcaption>{title}</StyledFigcaption>}
    {description && <StyledDescription>{description}</StyledDescription>}
  </StyledFigure>
);

export default Figure;
