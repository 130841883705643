import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledPageContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const StyledContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  ${breakpoint('sm')`
    width: ${({ theme }) => theme.breakpoints.sm}px
  `}

  ${breakpoint('md')`
    width: ${({ theme }) => theme.breakpoints.md}px
  `}

  ${breakpoint('lg')`
    width: ${({ theme }) => theme.breakpoints.lg}px
  `}

  ${breakpoint('xl')`
    width: ${({ theme }) => theme.breakpoints.xl}px
  `}

  ${({ noPadding }) => noPadding && css`
    padding: 0;
  `}
`;
