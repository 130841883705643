import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledMenu = styled.div`
  width: 100%; 
  height: 0;
  position: fixed;
  visibility: hidden;
  background-color: transparent;
  top: 104px;
  left: 0;
  padding: 0 20px;
  box-shadow: 0 24px 24px -2px rgb(0 0 0 / 40%);
  background-color: ${({ theme }) => theme.colors.background};
  overflow-y: auto;

  ${({ open }) => open && css`
    height: auto;
    visibility: visible;
    border-top: 1px solid rgb(112, 111, 111);
  `};

  ${breakpoint('lg')`
    height: auto;
    position: static;
    display: flex;
    flex-direction: row;
    top: 0;
    padding: 0;
    border: 0;
    visibility: visible;
    overflow: hidden;
  `}
`;

export const StyledMenuList = styled.ul`
  width: 100%;
  position: relative;
  display: block;
  padding: 40px 0;
  transition: all 0.35s ease;
  overflow-y: initial;

  ${breakpoint('lg')`
    position: static;
    display: flex;
    padding: 0;
    transition: none;
    overflow: hidden;
  `}
 `;

export const StyledMenuItem = styled.li`
  font-size: 1.6rem;
  margin: 0 0 25px 0;
  white-space: nowrap;
  position: static;
  text-align: center;

  ${breakpoint('lg')`
    font-size: 1.4rem;
    margin: 0 18px 0 0;
  `}

  &::before {
    display: none;
  }

  &:last-child {
    margin: 0;
    a {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 800;
    }
  }

  .menu-link {
    font-size: inherit;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    cursor: pointer;
  
    &:hover {
      border-bottom: 1px solid;
    }

    &.active {
      border-bottom: 0;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
