import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledTitle = styled.h3`
  text-align: center;
  white-space: pre-line;
  font-size: 1.6rem;

  ${breakpoint('md')`
    font-size: 1.8rem;
  `}
`;

export const StyledPrice = styled.span`
  font-size: 2rem;
  text-align: center;
`;

export const StyledPriceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  border: 4px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  padding: 20px;

  &:nth-child(4) {
    ${StyledTitle} {
      font-weight: 800;
      font-size: 2.3rem;
      margin-bottom: 30px;
    }
    ${StyledPrice} {
      font-weight: 700;
      white-space: pre-line;
    }
  }
`;

export const StyledTime = styled.span`
  font-size: 6rem;
  font-weight: 800;

  ${breakpoint('lg')`
    font-size: 8rem;
  `}
`;
