import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledSection = styled.section`
  width: 100%;
  padding-top: 60px;
  margin: 0;
  overflow: hidden;

  ${breakpoint('md')`
    padding-top: 100px;
  `}

  ${breakpoint('lg')`
    padding-top: 150px;
  `}

  h1 {
    margin-top: 0;
  }

  article:only-child {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ${({ backgroundColor }) => backgroundColor && css`
    background-color: ${backgroundColor};
  `}

  ${({ noContainer }) => noContainer && css`
    padding-left: 20px;
    padding-right: 20px;
  `}
`;
