import React from 'react';
import { StyledBurger } from './Burger.styled';

const Burger = ({ open, setOpen }) => (
  <StyledBurger
    open={open}
    onClick={() => setOpen(!open)}
    aria-label="Hamburger Menu"
  >
    <span />
    <span />
    <span />
  </StyledBurger>
);

export default Burger;
