import React from 'react';
import { StyledArticle } from './Article.styled';

const Article = ({ children }) => (
  <StyledArticle>
    {children}
  </StyledArticle>
);

export default Article;
