/* Plugins */
import React, { useState } from 'react';

/* Styles */
import {
  StyledContactForm,
  StyledInput,
  StyledInputLabel,
  StyledInputField,
  StyledTextArea,
  StyledSubmitButton,
} from './ContactForm.styled';

const ContactForm = () => {
  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <StyledContactForm
      name="contactForm"
      action="https://getform.io/f/ce6b57d7-362d-4e71-8ec8-b22e5a606ce1"
      method="POST"
    >
      {/* Name */}
      <StyledInput>
        <StyledInputField name="imię" type="text" placeholder="Imię" required />
      </StyledInput>
      {/* Phone */}
      <StyledInput>
        <StyledInputField name="numer_kontaktowy" type="tel" placeholder="Numer kontaktowy" />
      </StyledInput>
      {/* Email */}
      <StyledInput>
        <StyledInputField name="adres_email" type="email" placeholder="Adres email" required />
      </StyledInput>
      {/* Date */}
      <StyledInput>
        <StyledInputField type="date" onChange={handleDateChange} value={selectedDate} name="data_wynajmu" required />
        <StyledInputLabel>Data wynajmu</StyledInputLabel>
      </StyledInput>
      {/* Town */}
      <StyledInput>
        <StyledInputField name="miejscowość_wydarzenia" type="text" placeholder="Miejscowość wydarzenia" required />
      </StyledInput>
      {/* Additional information */}
      <StyledInput>
        <StyledTextArea name="dodatkowe_informacje" placeholder="Dodatkowe informacje" />
      </StyledInput>
      <StyledSubmitButton type="submit">Wyślij</StyledSubmitButton>
    </StyledContactForm>
  );
};

export default ContactForm;
