import React from 'react';
import { StyledPageContainer, StyledContainer } from './Container.styled';

const Container = ({ pageContainer, children }) => (
  pageContainer ? (
    <StyledPageContainer
      pageContainer={pageContainer}
    >
      {children}
    </StyledPageContainer>
  ) : (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
);

export default Container;
