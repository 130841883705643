import styled from 'styled-components';

export const StyledParagraph = styled.p`
  line-height: 1.3;
  margin-bottom: 20px;
`;

export const StyledParagraphBold = styled(StyledParagraph)`
  font-weight: 700;
`;
