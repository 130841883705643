import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledArticle = styled.article`
  width: 100%;
  margin-bottom: 20px;

  ${breakpoint('md')`
    margin-bottom: 25px;
  `}

  ${breakpoint('lg')`
    margin-bottom: 30px;
  `}

  &:last-child {
    margin: 0;
  }

  ul, ol {
    margin: 20px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    position: relative;
    font-size: 1.6rem;
    margin: 0 0 15px;
    line-height: 1.35;

    ${breakpoint('lg')`
      font-size: 1.8rem;
      margin: 0 0 18px;
    `}

    &:last-child {
      margin: 0 0 10px;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: -25px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.description};
    }
  }

  p {
    color: ${({ theme }) => theme.colors.description};
  }
`;
