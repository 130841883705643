/* eslint-disable import/extensions */
import React, { useState, useRef, useEffect } from 'react';
import {
  StyledVideoContainer,
  StyledVideo,
  StyledOverlay,
  PlayButton,
} from './Video.styled.js';
import PlayIcon from '../../../assets/images/icons/play-icon.svg';

const Videos = ({ autoPlay, videoSrc, ...props }) => {
  const [isWideScreen, setIsWideScreen] = useState(null);
  const [showOverlay, setShowOverlay] = useState(!autoPlay);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    if (isWideScreen >= 992) {
      setShowOverlay(!showOverlay);
      if (videoRef.current.paused) {
        videoRef.current.play();
        videoRef.current.controls = true;
      } else {
        videoRef.current.pause();
        videoRef.current.controls = false;
      }
    } else {
      return false;
    }
  };

  return (
    <StyledVideoContainer onClick={handleClick}>
      <StyledVideo
        muted
        controls
        autoPlay={autoPlay}
        ref={videoRef}
        {...props}
      >
        <source src={videoSrc} type="video/mp4" />
      </StyledVideo>
      {showOverlay && (
        <StyledOverlay>
          <PlayButton src={PlayIcon} alt="Play" />
        </StyledOverlay>
      )}
    </StyledVideoContainer>
  );
};

export default Videos;
