import React, { useEffect, useContext } from 'react';
import { Context } from '../../../../store/Context';
import { useWindowSize } from '../../../../utils/utils';
import {
  StyledMenu,
  StyledMenuItem,
  StyledMenuList,
} from './Menu.styled';

const menuLinkList = [
  {
    title: 'Nasz Team',
    path: '#nasz-team',
  },
  {
    title: 'Dlaczego my?',
    path: '#dlaczego-my',
  },
  {
    title: 'Jak działa',
    path: '#jak-dziala',
  },
  {
    title: 'Oferta',
    path: '#oferta',
  },
  // {
  //   title: 'Cennik',
  //   path: '#cennik',
  // },
  {
    title: 'Zapytaj o wycenę',
    path: '#zapytaj-o-wycene',
  },
];

const Menu = ({ open, setOpen }) => {
  const [width] = useWindowSize();
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    if (!open && width < 992) {
      dispatch({ type: 'OVERLAY_HIDE' });
    }
  }, [open, width, dispatch]);

  const handleClick = (event, path) => {
    event.preventDefault();
    const targetElement = document.querySelector(path);
    if (targetElement) {
      const offsetTop = open ? targetElement.offsetTop - 50 : targetElement.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
      if (state.overlayVisible) {
        dispatch({ type: 'OVERLAY_HIDE' });
      }
      if (open) {
        setOpen(false);
      }
    }
  };

  return (
    <StyledMenu open={open}>
      <StyledMenuList>
        {menuLinkList.map(({ title, path }) => (
          <StyledMenuItem key={title}>
            <a
              href={path}
              className="menu-link"
              onClick={(event) => handleClick(event, path)}
            >
              {title}
            </a>
          </StyledMenuItem>
        ))}
      </StyledMenuList>
    </StyledMenu>
  );
};

export default Menu;
