import React from 'react';
import { StyledSection } from './Section.styled';

const Section = ({
  backgroundColor, children, noContainer, ...props
}) => (
  <StyledSection
    backgroundColor={backgroundColor}
    noContainer={noContainer}
    {...props}
  >
    {children}
  </StyledSection>
);

Section.defaultProps = {
  noContainer: false,
  backgroundColor: 'black',
};

export default Section;
