/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import GoogleMapReact from 'google-map-react';
import { theme } from '../../../styles/theme';

const defaultProps = {
  center: {
    lat: 52.324960909743034,
    lng: 20.949145303206087,
  },
  zoom: 15,
};

const GoogleMap = () => {
  const renderMarkers = (map, maps) => {
    const marker = new maps.Marker({
      position: { lat: defaultProps.center.lat, lng: defaultProps.center.lng },
      map,
      title: theme.contacts.address,
    });
    return marker;
  };
  return (
    <div style={{
      height: '100%', minHeight: '300px', width: '100%', marginTop: 'auto',
    }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDUFJY56GxwFzhxhkIQeYUGvn7J7OBJiWU' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
    </div>
  );
};

export default GoogleMap;
