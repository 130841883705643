export const theme = {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    background: '#000000',
    text: '#ffffff',
    description: '#8f8f8f',
    primary: '#d39650',
    secondary: '#F3F4F5',
    tertiary: '#184E77',
  },
  contacts: {
    siteUrl: 'https://fotobudki360.net/',
    phone: '+48 515 565 693',
    email: 'kontakt@fotobudki360.net',
    companyName: 'Aesthetic Rafał Zdziarski',
    NIP: 'NIP: 5242703794',
    address: 'Józefa Mehoffera 32/37',
    postalCode: '03-144 Warszawa',
    province: 'woj. mazowieckie',
  },
};
