import React, { useEffect, useContext } from 'react';
import { Context } from '../../../store/Context';
import { useWindowSize } from '../../../utils/utils';
import { StyledPageWrapper } from './PageWrapper.styled';

const PageWrapper = ({ children }) => {
  const [, height] = useWindowSize();
  const [state, dispatch] = useContext(Context);

  const onScrollHandler = () => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 130) {
      dispatch({ type: 'STICKY_HEADER_SHOW' });
    } else {
      dispatch({ type: 'STICKY_HEADER_HIDE' });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler);
    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, [state]);

  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${height}px`);
  }, [height]);

  return (
    <StyledPageWrapper>
      {children}
    </StyledPageWrapper>
  );
};

export default PageWrapper;
