import React from 'react';
import { Link } from 'gatsby';
import Container from '../Container/Container';
import {
  StyledFooter,
  StyledFooterLink,
  StyledFooterCopyright,
} from './Footer.styled';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <StyledFooter>
    <Container>
      {/* Copyright section */}
      <StyledFooterCopyright>
        <StyledFooterLink
          as={Link}
          to="/polityka-prywatnosci/"
        >
          Polityka prywatności
        </StyledFooterLink>
        <span>
          <StyledFooterLink href="https://fotobudki360.net">fotobudki360.net</StyledFooterLink>
          {` © ${currentYear}`}
        </span>
      </StyledFooterCopyright>
    </Container>
  </StyledFooter>
);

export default Footer;
