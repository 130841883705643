import React from 'react';
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import * as messages from './WhatsApp.messages';
import { WhatsAppWidgetWrapper } from './WhatsApp.styled';

const WhatsApp = () => {
  const {
    phoneNumber, companyName, message, sendButton,
  } = messages;

  return (
    <WhatsAppWidgetWrapper>
      <WhatsAppWidget
        phoneNumber={phoneNumber}
        companyName={companyName}
        textReplyTime={false}
        message={message}
        sendButton={sendButton}
      />
    </WhatsAppWidgetWrapper>
  );
};

export default WhatsApp;
