import styled from 'styled-components';

export const StyledButton = styled.a`
  display: table;
  text-align: center;
  text-transform: inherit;
  text-decoration: none;
  font-weight: 600;
  font-size: 2rem;
  margin: 40px auto;
  border: 0;
  background-color: #d81e1e;
  color: ${({ theme }) => theme.colors.text};
  padding: 20px 40px;
  border-radius: 2px;
  letter-spacing: .5px;
  transition: background-color .3s ease, color .3s ease;
  white-space: pre-line;
  overflow: hidden;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #ff0000;
  }
`;
