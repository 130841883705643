import React from 'react';
import { StyledHeading } from './Heading.styled';

const Heading = ({
  title, headingLevel, highlightedLastWord,
}) => {
  const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const safeHeading = headingLevel ? headingLevel.toLowerCase() : '';
  const Tag = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';

  const words = title.split(' ');

  const changeLastWord = () => {
    const lastWord = words.slice(-2).join(' '); // Zmiana, aby zwrócić ostatnie dwa słowa
    const restOfWords = words.slice(0, -2).join(' ');

    if (words.length === 1) {
      return words[0]; // Jeśli jest tylko jedno słowo, zwracamy je bez zmian
    }

    return `${restOfWords} <span class='primaryColor'>${lastWord}</span>`;
  };

  const highlightLastWord = () => {
    const lastWord = words.slice(-1).join(' '); // Zmiana, aby zwrócić ostatnie słowo
    const restOfWords = words.slice(0, -1).join(' ');
    return `${restOfWords} <span class='primaryColor'>${lastWord}</span>`;
  };

  const handleHighlightedWords = () => {
    if (highlightedLastWord) {
      return highlightLastWord();
    }
    return changeLastWord(); // Domyślnie podświetl ostatnie dwa słowa
  };

  return (
    <StyledHeading
      as={Tag}
      dangerouslySetInnerHTML={{
        __html: handleHighlightedWords(),
      }}
    />
  );
};

export default Heading;
