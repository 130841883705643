import styled, { css } from 'styled-components';

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  opacity: 0;
  background-color: rgba(112, 111, 111, 0.6);
  visibility: hidden;
  z-index: 3;

  ${({ isVisible }) => isVisible && css`
    opacity: 1;
    visibility: visible;
  `}
`;
