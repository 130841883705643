import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledFooter = styled.footer`
  padding: 20px;
  font-size: 1.4rem;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.background};
  border-top: 1px solid rgb(112, 111, 111);
  margin-top: 20px;

  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;
  }

  ${breakpoint('lg')`
    margin-top: 50px;
  `}
`;

export const StyledFooterLinksCol = styled.div`
  min-width: 125px;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;

  ${breakpoint('lg')`
    width: 18%;
    margin-bottom: 20px;
  `}
`;

export const StyledFooterLinkCategory = styled.p`
  font-size: 2rem;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledFooterLink = styled.a`
  display: block;
  margin-bottom: 8px;
  color: #706F6F;
  padding: 2px;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledFooterCopyright = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #706F6F;

  ${StyledFooterLink} {
    display: inline-block;
  }
`;

export const StyledFooterCompanyDetails = styled.span`
  width: 100%;
  text-align: center;
  color: #706F6F;
  border-top: 1px solid #706F6F;
  padding-top: 20px;
`;
