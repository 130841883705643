export const initialState = {
  stickyHeader: false,
  megaMenuVisible: false,
  overlayVisible: false,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STICKY_HEADER_SHOW':
      return {
        ...state,
        stickyHeader: true,
      };
    case 'STICKY_HEADER_HIDE':
      return {
        ...state,
        stickyHeader: false,
      };
    case 'OVERLAY_TOGGLE':
      return {
        ...state,
        overlayVisible: !state.overlayVisible,
      };
    case 'OVERLAY_SHOW':
      return {
        ...state,
        overlayVisible: true,
      };
    case 'OVERLAY_HIDE':
      return {
        ...state,
        overlayVisible: false,
      };

    default:
      return state;
  }
};

export default Reducer;
