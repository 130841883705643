import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { theme } from '../../../styles/theme';
import Container from '../Container/Container';
import {
  StyledPreHeader,
  StyledPreHeaderContent,
  StyledPreHeaderLink,
  StyledPreHeaderIcon,
} from './PreHeader.styled';

const PreHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      icon1: file(name: { eq: "email-icon" }) {
        publicURL
      }
      icon2: file(name: { eq: "phone-icon" }) {
        publicURL
      }
    }
`);

  return (
    <StyledPreHeader>
      <Container>
        <StyledPreHeaderContent>
          <StyledPreHeaderLink href={`tel:${theme.contacts.phone}`}>
            <StyledPreHeaderIcon src={data.icon2.publicURL} alt="phone-icon" width="20" height="20" />
            {theme.contacts.phone}
          </StyledPreHeaderLink>
          <StyledPreHeaderLink href={`mailto:${theme.contacts.email}`}>
            <StyledPreHeaderIcon src={data.icon1.publicURL} alt="email-icon" width="20" height="20" />
            {theme.contacts.email}
          </StyledPreHeaderLink>
        </StyledPreHeaderContent>
      </Container>
    </StyledPreHeader>
  );
};

export default PreHeader;
