import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Container from '../Container/Container';
import Navigation from '../Navigation/Navigation';
import {
  StyledHeader,
  StyledHeaderContent,
  StyledHeaderLogoLink,
  StyledHeaderLogoImg,
} from './Header.styled';

const Header = ({ isSticky }) => {
  const data = useStaticQuery(graphql`
    query {
      file(name: {eq: "logo"}) {
        publicURL
      }
    }
  `);

  return (
    <StyledHeader isSticky={isSticky}>
      <Container>
        <StyledHeaderContent>
          <StyledHeaderLogoLink as={Link} to="/" title="Fotobudki360.net">
            <StyledHeaderLogoImg src={data.file.publicURL} alt="Wynajem fotobudki 360 - Fotobudki360.net" width="140" height="140" />
          </StyledHeaderLogoLink>
          <Navigation />
        </StyledHeaderContent>
      </Container>
    </StyledHeader>
  );
};

export default Header;
