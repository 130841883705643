/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
/* eslint-disable no-alert */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';

const CallPage = () => {
  useEffect(() => {
    const __cp = { id: 'LnLFiQlMuvC-t-WOCAMw03Uymgvldb7jPuX-o608vLQ', version: '1.1' };
    return (
      <script type="text/javascript">
        {(function (window, document) {
          const cp = document.createElement('script');
          cp.type = 'text/javascript';
          cp.async = true;
          cp.src = '++cdn-widget.callpage.io+build+js+callpage.js'.replace(/[+]/g, '/').replace(/[=]/g, '.');
          const s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(cp, s);
          if (window.callpage) {
            alert('You could have only 1 CallPage code on your website!');
          } else {
            window.callpage = function (method) {
              if (method == '__getQueue') {
                return this.methods;
              }
              if (method) {
                if (typeof window.callpage.execute === 'function') {
                  return window.callpage.execute.apply(this, arguments);
                }

                (this.methods = this.methods || []).push({ arguments });
              }
            };
            window.callpage.__cp = __cp;
            window.callpage('api.button.autoshow');
          }
        }(window, document))}
      </script>
    );
  }, []);

  return true;
};

export default CallPage;
