import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledImage = styled.img`
  width: 160px;

  ${breakpoint('lg')`
    width: 180px;
  `}
`;

export const StyledFigcaption = styled.figcaption`
  width: 200px;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  ${breakpoint('lg')`
    font-size: 2.3rem;
  `}
`;

export const StyledDescription = styled.p`
  margin-top: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.description};

  ${breakpoint('lg')`
     margin-top: 15px;
     font-size: 2rem;
  `}
`;

export const StyledFigure = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;

  ${({ smaller }) => smaller && css`
    ${StyledImage} {
      width: 100px;
    }
    
    ${StyledDescription} {
      font-size: 1.6rem;

      ${breakpoint('sm')`
        width: 60%;
      `}
    }
  `}

  ${({ team }) => team && css`
    border: 3px solid ${({ theme }) => theme.colors.primary};

    ${StyledFigcaption} {
      width: 100%;
      padding: 20px 0;
      text-transform: inherit;
      color: ${({ theme }) => theme.colors.background};
      background-color: ${({ theme }) => theme.colors.primary};
    }
  `}
`;
