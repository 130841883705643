import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { theme } from '../../../styles/theme';

export const StyledContactForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.primary};
  padding: 30px 20px;

  ${breakpoint('md')`
    padding: 60px 45px;
  `}
`;

export const StyledInput = styled.label`
  position: relative;
  margin-bottom: 16px;

  &.hidden {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledInputLabel = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  top: -10px;
  padding: 2px;
  margin: 10px;
  white-space: nowrap;
  color: rgb(39, 42, 52);
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.2;
`;

export const InputStyle = `
  width: 100%;
  display: block;
  padding: 0 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  color: rgb(39, 42, 52);
  border: 1px solid #797676;
`;

export const StyledInputField = styled.input`
  ${InputStyle}
  min-height: 50px;
  border-radius: 5px;

  &[type="date"] {
    padding: 0 20px 0 10px;
    margin-top: 25px;
  }

  &::placeholder { 
    color: #686A71; 
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
  }
`;

export const StyledTextArea = styled.textarea`
  ${InputStyle}
  padding: 20px;
  min-height: 150px;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;

  &::placeholder { 
    color: #686A71; 
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
  }
`;

export const StyledSubmitButton = styled.button`
  width: 150px;
  display: block;
  border: 0;
  background-color: #2147e2;
  padding: 15px;
  border-radius: 30px;
  margin-top: 15px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #0d2896;
  }
`;
