import { useLayoutEffect, useState } from 'react';

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight * 0.01]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

// replace dashes with spaces and remove digits
export const handleCleanTitle = (str) => str.replace(/-/g, ' ').replace(/[0-9]/g, '').trim();

// remove polish signs and numbers to make proper path
export const handleCleanPath = (link) => link.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[0-9]-/g, '');

export const handleCustomizeName = (name) => {
  if (name === 'scianka-tekstylna-prosta') {
    return 'ścianka tekstylna prosta';
  }
  if (name === 'scianka-tekstylna-lukowa') {
    return 'ścianka tekstylna łukowa';
  }
  if (name === 'scianka-banerowa') {
    return 'ścianka banerowa';
  }
  return handleCleanTitle(name);
};
