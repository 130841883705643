import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledPreHeader = styled.div`
  display: block;
  padding: 5px 0;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary};
  z-index: 999;

  ${({ isVisible }) => isVisible && css`
    display: none;
  `}
`;

export const StyledPreHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${breakpoint('lg')`
    justify-content: flex-end;
  `}
`;

export const StyledPreHeaderLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.background};
  font-weight: 600;
  font-size: 1.1rem;
  text-decoration: none;

  ${breakpoint('sm')`
    font-size: 1.3rem;
  `}

  &:last-child {
    margin-left: 20px;
  }
`;

export const StyledPreHeaderIcon = styled.img`
  width: 20px;
  margin-right: 5px;
`;
