import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledVideoContainer = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
`;

export const StyledVideo = styled.video`
  width: 100%;
  position: relative;
`;

export const StyledOverlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: -1px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;

  ${breakpoint('lg')`
    display: flex;
  `}
`;

export const PlayButton = styled.img`
  width: 150px;
  height: 150px;
  cursor: pointer;
`;
