/* eslint-disable import/extensions */

/* Plugins */
import React from 'react';

/* Styles */
import {
  StyledPriceBox,
  StyledTitle,
  StyledTime,
  StyledPrice,
} from './PriceBox.styled.js';

const PriceBox = ({ title, time, price }) => (
  <StyledPriceBox>
    <StyledTitle>{title}</StyledTitle>
    {time && <StyledTime>{time}</StyledTime>}
    <StyledPrice>{price}</StyledPrice>
  </StyledPriceBox>
);

export default PriceBox;
